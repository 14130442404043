html, body {
    height: 100%;
    margin: 0;
    padding: 0;
   
  }

  .full-height-div {
    height: 100%;
    _background-color: #f1f1f1;
  }