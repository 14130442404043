

.text-container {
    flex: 1;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.muiNavbarButton {
    text-transform: none !important; 
}
