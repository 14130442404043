

.footer-email,
.footer-developer {
    margin: 0;
    padding: 5px;
    font-size: 1.2em; /* Adjust this as needed */

}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    
    background-color: #333;
    color: white;
}

.footer-email {
    font-size: 1rem;
}

.footer-developer {
    font-size: 0.8rem;
}
