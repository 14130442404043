@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.html, .body {
    padding: 0px;
    margin: 0px;
}


.section{
    padding: 20px 0;
    position: relative;
    _background-color: #FFFFFF;
}
.section-with-space{
    padding: 60px 0;
}
.section-gray{
    background-color: #EEEEEE;
}
.section-nude{
    background-color: $bg-nude;
}
.section-gold{
    background-color: #caac90;
    color: $white-color;
}
.section-brown{
    background-color: #A59E94;
    color: $white-color;
}
.section-light-blue{
    background-color: $primary-color;
    color: $white-color;
}
.section-dark-blue{
    background-color: #506367;
    color: $white-color;
}
.section-dark{
    background-color: #0b0e11;
}
.section-dark2{
    background-color: #019fcb;
    color: $white-color;
}
.section-dark3{
    background-color: rgb(78, 0, 68);
    color: $white-color;
}
.section-image,
.section-gold,
.section-dark,
.section-dark2{
    position: relative;

    .title,
    .info-title,
    .card-plain .card-title{
        color: $white-color !important;
    }
    .category,
    .description,
    .card-plain .card-description,
    .card-plain .card-category{
        color: rgba($white-color, .7);
    }
    hr{
        border-color: rgba(255, 255, 255, 0.19);
    }
}
.section-dark3{
    position: relative;

    .title,
    .info-title,
    .card-plain .card-title{
        color: $white-color !important;
    }
    .category,
    .description,
    .card-plain .card-description,
    .card-plain .card-category{
        color: rgba($white-color, .7);
    }
    hr{
        border-color: rgba(255, 255, 255, 0.19);
    }
}
.page-header,
.carousel-caption{
    .title,
    .info-title{
        color: $white-color;
    }
}
[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}
.section-image{
    background-position: center center;
    background-size: cover;

    &:before{
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container{
        position: relative;
        z-index: 2;
    }
}

.section-login{
    min-height: 700px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .page-header{
        .content-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }
        .presentation-title{
            background: none !important;
        }
    }
}


[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"] {
    padding: 80px 0;
}
.section-image {
    background-position: center center;
    background-size: cover;

    &:before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    & .container {
        position: relative;
        z-index: 2;
    }
}

.page-header2 {
    _color: darkblue;
    font-weight: '700';
}


.subtitle1 {
    font-size: 1.5rem;
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1.5;      /* міжрядковий інтервал */
}
.body1 {
    font-size: 1.0rem;
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1.5;      /* міжрядковий інтервал */
}

.h4 {
    font-size: 2.0rem;
    font-weight: '700';
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1.5;      /* міжрядковий інтервал */
}

.landing_text {
    font-size: 1.2rem;
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1.5;      /* міжрядковий інтервал */
    text-align: left;
    white-space: pre-line;
}

.landing_list {
    font-size: 1.4rem;
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1;      /* міжрядковий інтервал */
    font-weight: bold;
}

.landing_list_dot {
    font-size: 1.1rem;
    font-family: 'Roboto', Arial, sans-serif;
    letter-spacing: 0.5px; /* відстань між буквами */
    line-height: 1.3;      /* міжрядковий інтервал */
    font-weight: noraml;
}