.landing-block {
    _width: 100%;
    padding: 0px;
    transition: background-color 0.3s ease;
}

.landing-block-light {
    _background-color: #f5f5f5;
    background-color: #ffffff;
    color: #333333;
}

.landing-block-dark {
    background-color: #1b0046;
    color: #f5f5f5;
}
/*---------------------------------------*/
.landing-block-light2 {
    position: relative;
    width: 100%;
    height: 100%;
    color: #1c0080;
}

.landing-block-light2::before {
    content: "";
    background: url('../assets/content/business_council/paper2.jpg') center/cover no-repeat;
    opacity: 0.5; /* Прозорість 50% */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Забезпечує, що фон розташований під іншим вмістом */
}

.landing-block-dark2 {
    background-color: #4300afc9;
    position: relative;
    width: 100%;
    height: 100%;
    color: #f5f5f5;
}
.landing-block-dark2 a {
    color: lightblue;
}
.landing-block-dark2 a:hover {
    color: white; /* Колір лінків при наведенні на них курсору */
}

.landing-block-dark2::before {
    
    content: "";
    background: url('../assets/content/business_council/dark2.jpeg') center/cover no-repeat;
    opacity: 1; /* Прозорість 50% */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Забезпечує, що фон розташований під іншим вмістом */
}
/*-----------------------------------------------*/
.landing-block-light3 {
    position: relative;
    width: 100%;
    height: 100%;
    color: #019fcb;
}

.landing-block-light3::before {
    content: "";
    _background: url('../assets/content/business_council/paper.jpg') center/cover no-repeat;
    opacity: 0.5; /* Прозорість 50% */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Забезпечує, що фон розташований під іншим вмістом */
}

.landing-block-dark3 {
    background-color: #019fcb;
    position: relative;
    width: 100%;
    height: 100%;
    color: #f5f5f5;
}
.landing-block-dark3 a {
    color: lightblue;
}
.landing-block-dark3 a:hover {
    color: white; /* Колір лінків при наведенні на них курсору */
}

.landing-block-dark3::before {
    
    content: "";
    background: url('../assets/content/business_council/dark2.jpeg') center/cover no-repeat;
    opacity: 1; /* Прозорість 50% */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Забезпечує, що фон розташований під іншим вмістом */
}
/*-----------------------------------------------*/
.landing-block-light5 {
    position: relative;
    width: 100%;
    height: 100%;
    color: #800080;
}

.landing-block-light5::before {
    content: "";
    opacity: 0.5; /* Прозорість 50% */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; /* Забезпечує, що фон розташований під іншим вмістом */
}