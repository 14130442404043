

.donate-box {
    font-size: 14px;
    padding: 16px;
    _border: 1px solid red;
}

.pay-button {
    border: none !important; 
    display:inline-block !important;
    text-align: center !important;
    padding: 5px 24px !important;
    color: #fff !important; 
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.12); 
    font-size:16px !important; 
    line-height: 1.75 !important; 
    font-weight: 600 !important; 
    font-family: 'Open Sans', sans-serif; 
    cursor: pointer !important; 
    border-radius: 8px !important;
    background: #6686dd !important;
}

.pay-button-img {
    vertical-align: middle !important;
}

.pay-button-span {
    vertical-align:middle; 
    /*!important; */
    margin-left: 8px !important;
}