/* JsonEditor.css */
textarea {
    font-family: 'Courier New', Courier, monospace;
    background-color: #272822;
    color: #f8f8f2;
    border: 1px solid #f8f8f2;
    padding: 10px;
    border-radius: 5px;
  }
  
  textarea:focus {
    outline: none;
    border: 1px solid #66d9ef;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #272822;
    padding: 20px;
    box-sizing: border-box;
  }
  
  button {
    margin: 10px 0;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .split-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .editor, .content {
    overflow: auto;
  }
  
  .splitter {
    width: 5px;
    cursor: col-resize;
    background-color: #ccc;
  }
  