@keyframes slideFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.slide-in-left {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
}

.slide-in-right {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
}

.slide-in-left.active, .slide-in-right.active {
    transform: translateY(0);
    opacity: 1;
}

.slide-in-content {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
}

.slide-in-content.active {
    transform: translateY(0);
    opacity: 1;
}