/* NavbarStyles.css */

html  .app-bar {
    transition: 0.1s;
    background-color: #ffffff;
    color: #2793ff;
}

.navbar-container {
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
}

.navbar-logo {
    display: flex;
    align-items: left;
    height: 80px;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 14pt;
}

.language-button {
    display: flex;
    flex-direction: column; /* or 'row' for larger screens */
}

.menu-button {
    margin: 4px;
    height: 30px;
}
.menu-button-border {
    border: 1px solid #2793ff;
    border-radius: 3px;
    margin: 4px;
    height: 30px;
}
.menu-button-ismobile {
    margin: 4px;
    height: 30px;
}

.menu-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.mobile-menu-container {
    margin: 4px;
}

/* MyNavbarStyles.css */
html  .my-app-bar {
    transition: 0.1s;
    background-color: #1300b9;
    color: #c8e3ff;
}
.my-menu-buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.my-menu-button {
    border: 1px solid #2793ff;
    border-radius: 3px;
    margin: 4px;
    height: 30px;
}