.carusel {
    height: 60vh;
    position: relative;
    overflow: hidden;
    _background-color: darkblue;
}

.carusel-image {
    opacity: 0;
    transition: opacity 1s;
    height: 60vh; 
    width: auto;
    position: absolute;
    top: 0;
    left: 50%;          /* Центрування по горизонталі */
    transform: translateX(-50%); /* Переміщення на половину ширини зображення назад, щоб центрувати його */
    z-index: 1; 
}

.carusel-image.active {
    opacity: 1;
    z-index: 2; 
}

/* Для розміру ширини "sm" (зазвичай 600px - 960px для Material-UI) */
@media (min-width: 600px) and (max-width: 959px) {
    .carusel {
        height: 40vh; /* Третина висоти екрану */
    }

    .carusel-image {
        height: 40vh;
    }
}
@media (max-width: 600px) {
    .carusel {
        height: 33.33vh; /* Третина висоти екрану */
    }

    .carusel-image {
        height: 33.33vh;
    }
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    z-index: 3; 
    
    border-radius: 20%;
}

.carousel-button-left {
    left: 0;
}

.carousel-button-right {
    right: 0;
}

/* Адаптивність */
@media (max-width: 768px) {
    .carousel-button {
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
    }
}

/* Затемнення */
.carusel::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30vh;
    _background: linear-gradient(to top, rgba(0, 0, 128, 0.7), transparent);
}

/* Текстовий контент */
.carousel-text {
    z-index: 1000; 
    position: absolute;
    bottom: 20vh; 
    left: 50%;
    transform: translateX(-50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 3.5rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    text-align: center;
}
@media (max-width: 600px) {
    .carousel-text {
        font-size: 2rem;
    }
}
/* Індикатори */
.carousel-indicators {
    z-index: 1000; 
    position: absolute;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.indicator {
    width: 15px;
    height: 15px;
    background: white;
    border-radius: 50%;
    opacity: 0.5;
}

.indicator.active {
    opacity: 1;
}

.carousel-miniatures {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 8px;
}

.miniature-image {
    height: 50px; 
    width: auto;
    margin-top: 8px;
    padding: 1px;
    
    opacity: 0.5;
}

.miniature-image.active {
    opacity: 1;
    border: 2px solid white;
}

.button-miniatures {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 0 !important;
}
